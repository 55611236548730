import jwtDecode from 'jwt-decode';
import SuperFetch from './superFetch';

export const Roles = {
    SUPER_ADMIN: "ROLE_SUPER_ADMIN",
    ADMIN: "ROLE_ADMIN",
    EMPLOYEE: "ROLE_EMPLOYEE",
    USER: "ROLE_USER",
    REFERENT: "ROLE_REFERENT"
};


class AuthHelper {
    login = async userInfo => {
        if (!userInfo.username || !userInfo.password) {
            return {error: 'please fill in the input'};
        }
        return await SuperFetch.post('login', userInfo).then(response => {
            return this.checkExpirity(response.token);
        });
    };

    isLoggedIn() {
        return !!localStorage.getItem('id_token');
    }

    checkExpirity = token => {
        if (!token) {
            return {
                error: 'not matched',
            };
        }
        try {
            const profile = jwtDecode(token);

            const expiredAt = profile.expiredAt || profile.exp * 1000;

            if (expiredAt > new Date().getTime()) {
                return {
                    ...profile,
                    token,
                    expiredAt: new Date(expiredAt),
                };
            } else {
                return {error: 'Token expired'};
            }
        } catch (e) {
            console.log(e);

            return {error: 'Server Error'};
        }
    };

    getCurrentUserId() {
        return localStorage.getItem('id')
    }

    getUsername() {
        return localStorage.getItem('username')
    }

        getRoles() {
        return localStorage.getItem('roles')
    }

    getMainRole(rolesString) {

        if (rolesString) {
            let roles = rolesString.split(',');
            if(roles.includes(Roles.USER)) {
                return Roles.USER
            } else {
                if(roles.includes(Roles.SUPER_ADMIN)) {
                    return Roles.SUPER_ADMIN
                } else if(roles.includes(Roles.ADMIN)){
                    return Roles.ADMIN
                } else {
                    return Roles.EMPLOYEE
                }
            }
        }
        return undefined
    }

    getStringRole(roleString){
        if (roleString) {
            return roleString.replace(/ROLE_/g, "").toLocaleLowerCase().replace(/_/g, " ")
        }
    }

    getAdminRoleString(){
        return Roles.ADMIN
    }

    getSuperAdminRoleString(){
        return Roles.SUPER_ADMIN
    }

    getUserRoleString(){
        return Roles.USER
    }
}

export default new AuthHelper();
