import actions from './actions';
import createNotification from "@src/components/Notification";

const initState = {
    id:null,
    idToken: null,
    loginResponse: null,
    resetOk: false,
    recoverPasswordOk: false,
    registrationOk: false,
    quoteRegistrationOk: false,
    registrationSuccess: false,
    roles: null
    //info: null
};

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.INIT:
            return {initState}
        case actions.LOGIN_REQUEST:
            return {...state, loading: true};
        case actions.LOGIN_SUCCESS:
            return {...state, id:action.id, idToken: action.token, roles: action.roles, loading: false, pinMustBeChanged: false}; //TODO gestione dati utente loggato
        case actions.LOGIN_ERROR:
            return {...state, loading: false};
        case actions.LOGOUT:
            return initState;
        case actions.REFRESH_TOKEN:
            return {...state};
        case actions.FIRST_QUOTE_SIGN_UP:
            return {
                ...state,
                quoteRegistrationOk: false
            }
        case actions.TRUCKER_SIGN_UP_REQUEST:
            return {
                ...state,
                registrationOk: false
            }
        case actions.DEALER_SIGN_UP_REQUEST:
            return {
                ...state,
                registrationOk: false
            }
        case actions.FIRST_QUOTE_SIGN_UP_SUCCESS:
            createNotification("info", 'Richiesta di preventivo inviata con successo, ti è stata inviata una mail di conferma');
            return {
                ...state,
                quoteRegistrationOk: true
            }
        case actions.DEALER_SIGN_UP_SUCCESS:
            createNotification("info", 'Registrazione avvenuta con successo, ti è stata inviata una mail di conferma');
            return {
                ...state,
                registrationOk: true
            };
        case actions.DEALER_SIGN_UP_ERROR:
            action.payload.message && action.payload.message !== '' && createNotification("error", action.payload.message);
            return {
                ...state,
                registrationOk: false
            };
        case actions.TRUCKER_SIGN_UP_SUCCESS:
            createNotification("info", 'Registrazione avvenuta con successo, ti è stata inviata una mail di conferma');
            return {
                ...state,
                registrationOk: true
            };
        case actions.TRUCKER_SIGN_UP_ERROR:
            action.payload.message && action.payload.message !== '' && createNotification("error", action.payload.message);
            return {
                ...state,
                registrationOk: false
            };
        case actions.RECOVER_PASSWORD_SUCCESS:
            createNotification("info", 'È stata inviata una e-mail all\'indirizzo indicato con le istruzioni per il reset della password');
            return {...state, recoverPasswordOk: true};
        case actions.RECOVER_PASSWORD_ERROR:
            createNotification("error", action.payload.message);
            break;
        case actions.RESET_PASSWORD_SUCCESS:
            createNotification("info", 'Password cambiata con successo');
            return {...state, resetOk: true};
        case actions.RESET_PASSWORD_ERROR:
            createNotification("error", action.payload.message);
            break;
            /*
        case actions.USER_INFO_SUCCESS:
            return {...state, info: action.payload}
            */
        case actions.SIGN_UP:
            return {...state, registrationSuccess: null}
        case actions.SIGN_UP_SUCCESS:
            return {...state, registrationSuccess: true}
        case actions.SIGN_UP_ERROR:
            return {...state, registrationSuccess: false}
        case actions.SET_ROLES:
            return {...state, roles: action.payload }
        default:
            return state;
    }
}
