const themeConfig = {
    topbar: 'themedefault',
    sidebar: 'themedefault',
    layout: 'themedefault',
    theme: 'themedefault',
    A4GTheme: window.A4GTheme,
    A4GPrimaryColor: "#394049",
    A4GBlue: "#17A4CC",
    A4GBlueOpaque: "#1285A5",
    SIOPrimaryColor: "#0977CB"
};

const version = "2.0.4"

let apiUrl = window.endpoints.apiUrl

const endpoints = {

    //download
    download: apiUrl + "/download",

    //userInfo
    userInfo: apiUrl + "/user/info",
    updateUserData: apiUrl + "/user/updateUserData",
    updateUserPassword: apiUrl + "/user/updateUserPassword",
    deleteProfile: apiUrl + "/user/deleteProfile",

    //login
    login: apiUrl + "/auth/login",

    //Domain

    users: apiUrl + "/users",
    transepts: apiUrl + "/transepts",
    companies: apiUrl + "/companies",
    detections: apiUrl + "/detections",
    detectionsSaveFromUpload: apiUrl + "/detections/saveFromUpload",
    baseDetections: apiUrl + "/baseDetections",
    vegetationDetections: apiUrl + "/baseDetections/vegetationDetections",
    apoideaDetections: apiUrl + "/baseDetections/apoideaDetections",
    mothDetections: apiUrl + "/baseDetections/mothDetections",
    vegetationRecords: apiUrl + "/vegetationRecords",
    apoideaRecords: apiUrl + "/apoideaRecords",
    mothRecords: apiUrl + "/mothRecords",
    vegetationAssign: apiUrl + "/detections/assignVegetationDetection",
    apoideaAssign: apiUrl + "/detections/assignApoideaDetection",
    mothAssign: apiUrl + "/detections/assignMothDetection",


    //autocomplete
    companyAutocomplete: apiUrl + "/companies/search",
    customUserAutocomplete: apiUrl + "/users/search",
    apoideaAutocomplete: apiUrl + "/apoidea/search",
    mothAutocomplete: apiUrl + "/moth/search",
    floraAutocomplete: apiUrl + "/flora/search",

    //registration
    register: apiUrl + "/auth/register",
    recoverPasswordByEmail: apiUrl + "/registration/resetPasswordByEmail",
    dealerSignUp: apiUrl + "/register/dealer",

    getImageResource: apiUrl + "/file/getImageResource",
    refreshToken: apiUrl + "/oauth/access_token",
    getImageFromText: apiUrl + "/file/getImageFromText",

    //password recovery
    resetPassword : apiUrl + "/registration/resetPassword",

    //moth
    getAllMoth : apiUrl + "/moth/getAllMoth",
    //apoidea
    getAllApoidea : apiUrl + "/apoidea/getAllApoidea",
    //vegetation
    getAllVegetation : apiUrl + "/flora/getAllVegetation",

    //update
    updateApoidea : apiUrl + "/apoidea/editApoidea",
    updateMoth : apiUrl + "/moth/editMoth",
    updateVegetation : apiUrl + "/flora/editFlora",


    //CRUD
    save: '/save',
    put: '/put',
};


export {endpoints, themeConfig, version}
