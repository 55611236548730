const actions = {

    INITIALIZE_TRANSEPT: 'INITIALIZE_TRANSEPT',

    GET_ALL_TRANSEPTS: 'GET_ALL_TRANSEPTS',
    GET_ALL_TRANSEPTS_SUCCESS: 'GET_ALL_TRANSEPTS_SUCCESS',
    GET_ALL_TRANSEPTS_ERROR: 'GET_ALL_TRANSEPTS_ERROR',

    GET_TRANSEPT: 'GET_TRANSEPT',
    GET_TRANSEPT_SUCCESS: 'GET_TRANSEPT_SUCCESS',
    GET_TRANSEPT_ERROR: 'GET_TRANSEPT_ERROR',

    SAVE_TRANSEPT: 'SAVE_TRANSEPT',
    SAVE_TRANSEPT_SUCCESS: 'SAVE_TRANSEPT_SUCCESS',
    SAVE_TRANSEPT_ERROR: 'SAVE_TRANSEPT_ERROR',

    UPDATE_TRANSEPT: 'UPDATE_TRANSEPT',
    UPDATE_TRANSEPT_SUCCESS: 'UPDATE_TRANSEPT_SUCCESS',
    UPDATE_TRANSEPT_ERROR: 'UPDATE_TRANSEPT_ERROR',

    UPDATE_FIELD: 'UPDATE_FIELD',
    UPDATE_FIELD_SUCCESS: 'UPDATE_FIELD_SUCCESS',
    UPDATE_FIELD_ERROR: 'UPDATE_FIELD_ERROR',

    DELETE_TRANSEPT: 'DELETE_TRANSEPT',
    DELETE_TRANSEPT_SUCCESS: 'DELETE_TRANSEPT_SUCCESS',
    DELETE_TRANSEPT_ERROR: 'DELETE_TRANSEPT_ERROR',


    initializeTransect: () => ({
        type: actions.INITIALIZE_TRANSEPT
    }),

    getAll: (pagination) => ({
        type: actions.GET_ALL_TRANSEPTS,
        pagination: pagination
    }),

    getTransectById: (entityToLoadId) => ({
        type: actions.GET_TRANSEPT,
        id: entityToLoadId,
    }),

    save: (data) => ({
        type: actions.SAVE_TRANSEPT,
        payload: data
    }),

    updateTransept: (data) => ({
        type: actions.UPDATE_TRANSEPT,
        payload: data,
    }),

    updateField: (data) => ({
        type: actions.UPDATE_FIELD,
        payload: data,
    }),

    deleteData: (id) => ({
        type: actions.DELETE_TRANSEPT,
        id: id
    }),

};
export default actions;
