export function capitalize(string) {
    if (string && string.length >= 1) {
        let capitalizedString = string.charAt(0).toUpperCase()
        if (string.length > 1) {
            capitalizedString += string.slice(1)
        }
        return capitalizedString
    }
    return null
}


export function reverseString(str) {
    let splitString = str.split("")
    let reverseArray = splitString.reverse()
    let joinArray = reverseArray.join("")
    return joinArray
}

export function getFileNameFromFullPath(fullPath) {
    if (fullPath) {
        let splittedFullPath
        let windowsSplit = fullPath.split("\\")
        if (windowsSplit.length === 1) {
            splittedFullPath = fullPath.split("/")
        } else {
            splittedFullPath = windowsSplit
        }
        return splittedFullPath[splittedFullPath.length - 1]
    }
    return null
}

export function paginationQS(paginationData) {
    let queryString = ''
    if (paginationData) {
        if (paginationData.size >= 1 && paginationData.page >= 0) queryString = queryString + '?size=' + paginationData.size + '&page=' + paginationData.page
        if (paginationData.sort && paginationData.order) queryString = queryString + '&sort=' + paginationData.sort + '&order=' + paginationData.order
        if(paginationData.role!=null){
            queryString = queryString+"&role="+paginationData.role
        }
    }

    return queryString
}

export function randomFileName(length) {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result.push(characters.charAt(Math.floor(Math.random() *
            charactersLength)));
    }
    result.push(".docx")
    return result.join('');
}

export function sortStrings(a, b) {
    var nameA = a.toUpperCase();
    var nameB = b.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    return 0;
}