import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {endpoints} from '@src/config/site.config'
import SuperFetch from '@src/library/helpers/superFetch';
import actions from './actions';
import appAction from "@src/redux/app/actions";
import {paginationQS} from "@src/library/utils/stringUtils";

export function* getAll() {

    yield takeEvery(actions.GET_ALL_DETECTION, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.detections + paginationQS(data.pagination), null, true)

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_DETECTION_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_DETECTION_ERROR,
                payload: error
            });
        }
    })
}

export function* get() {

    yield takeEvery(actions.GET_DETECTION, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.detections + '/' + data.id, null, true)

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_DETECTION_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_DETECTION_ERROR,
                payload: error
            });
        }
    })
}

export function* save() {
    yield takeEvery(actions.SAVE_DETECTION, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.post, endpoints.detections, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.SAVE_DETECTION_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.SAVE_DETECTION_ERROR,
                payload: error
            });
        }
    })
}

export function* update() {
    yield takeEvery(actions.UPDATE_DETECTION, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.put, endpoints.detections + "/" + data.payload.id, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_DETECTION_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_DETECTION_ERROR,
                payload: error
            });
        }
    })
}

export function* updateAssignVegetation() {
    yield takeEvery(actions.ASSIGN_VEGETABLE, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.PATCH, endpoints.vegetationAssign + "/" + data.id + "/" + data.payload, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.ASSIGN_VEGETABLE_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.ASSIGN_VEGETABLE_ERROR,
                payload: error
            });
        }
    })
}

export function* updateAssignApoidea() {
    yield takeEvery(actions.ASSIGN_APOIDEA, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.PATCH, endpoints.apoideaAssign + "/" + data.id + "/" + data.payload, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.ASSIGN_APOIDEA_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.ASSIGN_APOIDEA_ERROR,
                payload: error
            });
        }
    })
}

export function* updateAssignMoth() {
    yield takeEvery(actions.ASSIGN_MOTH, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.PATCH, endpoints.mothAssign + "/" + data.id + "/" + data.payload, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.ASSIGN_MOTH_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.ASSIGN_MOTH_ERROR,
                payload: error
            });
        }
    })
}


export function* deleteData() {
    yield takeEvery(actions.DELETE_DETECTION, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.delete, endpoints.detections + "/" + data.id, null, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.DELETE_DETECTION_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.DELETE_DETECTION_ERROR,
                payload: error
            });
        }
    })
}

export function* getDetectionDetails() {
    yield takeEvery(actions.GET_DETECTION_DETAILS, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.detections + "/"+data.payload, null, true)

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_DETECTION_DETAILS_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_DETECTION_DETAILS_ERROR,
                payload: error
            });
        }
    })
}

export function* saveFromUpload() {
    yield takeEvery(actions.SAVE_DETECTION_FROM_UPLOAD, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.post, endpoints.detectionsSaveFromUpload , data.payload, true)

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.SAVE_DETECTION_FROM_UPLOAD_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.SAVE_DETECTION_FROM_UPLOAD_ERROR,
                payload: error
            });
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getAll),
        fork(get),
        fork(save),
        fork(update),
        fork(updateAssignVegetation),
        fork(updateAssignApoidea),
        fork(updateAssignMoth),
        fork(deleteData),
        fork(getDetectionDetails),
        fork(saveFromUpload)
    ]);
}