import {createGlobalStyle} from 'styled-components';
import {font, palette} from 'styled-theme';
import 'antd/dist/antd.css';

const GlobalStyles = createGlobalStyle`
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .global-buttons-color{
      background: ${palette('digistone', 0)} !important;
  }
  
  .digistone-buttons-color{
   background: ${palette('digistone', 0)} !important;
  }
  
   .digistone-secondary-color{
    background: ${palette('digistone', 1)} !important;
  }
  
   .digistone-primary-color{
    background: ${palette('digistone', 2)} !important;
  }
  
   .digistone-text-color{
    background: ${palette('digistone', 3)} !important;
  }
  
  
  .ant-form-item-label > label{
  color: ${palette('digistone', 3)} !important;
 
  }
  
  .ant-form-item-label{
  text-align : left !important;
  }
  

  
  .menuCollapsed {
  color: #3312F9 !important;
  }
  
  .menuOpen {
  color: white !important;
  }

  
  .transition-img {
   opacity: 0;
   max-width:100%;
   max-height : 500px;
   -webkit-animation: appear 1s;
   animation: appear 1s;
   -webkit-animation-fill-mode: forwards;
   animation-fill-mode: forwards;
   animation-delay: 0s;
  }
  
  @-webkit-keyframes appear {
     0% { opacity: 0; }
     100% { opacity: 1; }  
  }
  
  @keyframes appear {
     0% { opacity: 0; }
     100% { opacity: 1; }  
  }
  
      input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
}
  .ant-input:hover{
  border-right-width:2px !important
  }
  
  .ant-layout-sider-children{
  border-right: 1px solid #707070;
  }

  .logo {
    font-size: 32px;
    font-weight: 700;
    color: #fff;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #e9f2c0 !important;
  }
  
  .ant-row.ant-form-item {
    margin-bottom: 5px;
  }

  .has-success.has-feedback {
    .ant-select {
      .ant-select-selection {
        .ant-select-selection__rendered {
          .ant-select-selection__placeholder {
            display: none !important;
          }
        }
      }
    }
  }
  
  
  
  .ant-upload.ant-upload-select-picture-card{
  background-color: #ffffff30 !important;
    border: 1px solid #ffffff30;
    border-radius: 0px ;
  }
  
  .ant-upload.ant-upload-select-picture-card {
    max-height : 500px !important;
    width: 100%;
   }

  .ant-upload.ant-upload-select-picture-card > .ant-upload{
      background: #31394c !important;
      color : #ffffff70 !important;
  }

  /*-----------------------------------------------*/ 
  // style for project category menu [ScrumBoard]
  /*-----------------------------------------------*/
  .project-category {
    .ant-select-dropdown-menu {
      .ant-select-dropdown-menu-item {
        padding: 8px 12px;
        color: #000000;
        font-family: 'Roboto';
        font-weight: 400;
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for project menu [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-dropdown {
    &.project-menu {
      width: 280px;
      top: 133px !important;
      
      .ant-dropdown-menu {
        padding: 0;
        overflow: hidden;

        .ant-dropdown-menu-item {
          min-height: 54px;
          line-height: auto;
          display: flex;
          align-items: center;
          padding: 10px 20px;

          &:first-child {
            padding: 0;
            border-bottom: 1px solid #f4f6fd;

            &:hover,
            &:focus {
              background-color: #ffffff;
            }
          }

          &:hover,
          &:focus {
            background-color: #F3F5FD;
          }

          &:last-child {
            background-color: #E6EAF8;
          }
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for popover [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-popover {
    .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      .ant-checkbox-group-item {
        margin: 5px 0;
        span {
          font-size: 14px;
          color: #788195;
          text-transform: capitalize;
        }
      }
    }
  }

  /*-----------------------------------------------*/ 
  // style for modal [ScrumBoard]
  /*-----------------------------------------------*/
  .ant-modal-wrap {
    .ant-modal {
      .ant-modal-content {
        .ant-modal-body {
          .render-form-wrapper {
            padding: 10px;
            h2 {
              margin: 0;
            }
            form {
              padding: 15px 0 3px;
              .field-container {
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }


/*-----------------------------------------------*/ 
  // style form previous GlobalStyles
  /*-----------------------------------------------*/

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background: #e9f2c0 !important;
}

font-family: ${font('primary', 0)};


h1,
h2,
h3,
h4,
h5,
h6 {

  color: #FFFFFF !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
img,
svg {
  &::selection {
    background: ${palette('primary', 0)};
    color: #fff;
  }
}

.ant-row:not(.ant-form-item) {
  ${'' /* margin-left: -8px;
  margin-right: -8px; */};
  &:before,
  &:after {
    display: none;
  }
}

.ant-row > div {
  padding: 0;
}

.isoLeftRightComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.isoCenterComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

`;

export default GlobalStyles;
