import actions from './actions';
import createNotification from "@src/components/Notification";

const initState = {
    companies: null,
    company: null,
    updateList: false,
    updatePage: false,
    entityToLoadId: null,
    backToList: false,
    autocomplete: null
};

export default function company(state = initState, action) {
    switch (action.type) {
        case actions.INITIALIZE_COMPANY:
            return {
                initState,
                updateList: true
            };
        case actions.GET_ALL_COMPANIES_SUCCESS:
            return {
                ...state,
                companies: action.payload.value,
                totalResults: action.payload.totalResults,
                updateList: false,
                backToList: false
            };
        case actions.GET_COMPANY:
            return {
                ...state,
                company: null,
                updatePage: false
            };
        case actions.GET_COMPANY_SUCCESS:
            return {
                ...state,
                company: action.payload.value,
                updatePage: true
            };
        case actions.SAVE_COMPANY:
            return {
                ...state,
                updateList: false,
                backToList: false
            };
        case actions.SAVE_COMPANY_SUCCESS:
            createNotification('info', 'Operazione avvenuta con successo')
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.UPDATE_COMPANY:
            return {
                ...state,
                backToList: false,
                updateList: false
            };
        case actions.UPDATE_COMPANY_SUCCESS:
            createNotification('info', 'Operazione avvenuta con successo')
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.DELETE_COMPANY:
            return {
                ...state,
                updateList: false
            };
        case actions.DELETE_COMPANY_SUCCESS:
            createNotification('info', 'Operazione avvenuta con successo')
            return {
                ...state,
                updateList: true
            };
        default:
            return state;
    }
}
