import actions from './actions';

const initState = {
    apoidea: null,
    flora: null,
    moth: null,
    mothAll: null,
    apoideaAll: null,
    vegetationAll: null,
    apoUpdated : false,
    mothUpdated : false,
    vegetationUpdated : false
};

export default function data(state = initState, action) {
    switch (action.type) {
        case actions.SEARCH_APOIDEA_BY_NAME_LIKE:
            return {
                ...state,
                apoidea: null
            };
        case actions.SEARCH_APOIDEA_BY_NAME_LIKE_SUCCESS:
            return {
                ...state,
                apoidea: action.payload
            };
        case actions.SEARCH_FLORA_BY_NAME_LIKE:
            return {
                ...state,
                flora: null
            };
        case actions.SEARCH_FLORA_BY_NAME_LIKE_SUCCESS:
            return {
                ...state,
                flora: action.payload
            };
        case actions.SEARCH_MOTH_BY_NAME_LIKE:
            return {
                ...state,
                moth: null
            };
        case actions.SEARCH_MOTH_BY_NAME_LIKE_SUCCESS:
            return {
                ...state,
                moth: action.payload
            };
        case actions.GET_ALL_MOTH_SUCCESS:
            return {
                ...state,
                mothAll: action.payload
            };
        case actions.GET_ALL_MOTH_ERROR:
            return {
                ...state
            };
        case actions.GET_ALL_APOIDEA_SUCCESS:
            return {
                ...state,
                apoideaAll: action.payload
            };
        case actions.GET_ALL_APOIDEA_ERROR:
            return {
                ...state
            };
        case actions.GET_ALL_VEGETATION_SUCCESS:
            return {
                ...state,
                vegetationAll: action.payload
            };
        case actions.GET_ALL_VEGETATION_ERROR:
            return {
                ...state
            };
        case actions.UPDATE_RECORD_APOIDEA_SUCCESS:
            let indexToEdit = state.apoideaAll.findIndex(apo=> apo.id ===action.payload.id)
            if(indexToEdit!==-1){
                state.apoideaAll[indexToEdit].specieName = action.payload.specieName
                state.apoideaAll[indexToEdit].specieNameDotted = action.payload.specieNameDotted
                let newApoideaAll = state.apoideaAll
                state.apoUpdated = true
                return {...state, apoideaAll: newApoideaAll}
            }else{
                return {...state}
            }
        case actions.UPDATE_RECORD_APOIDEA_ERROR:
            return {
                ...state
            };
        case actions.UPDATE_RECORD_MOTH_SUCCESS:
            let indexToEditMoth = state.mothAll.findIndex(moth=> moth.id ===action.payload.id)
            if(indexToEditMoth!==-1){
                state.mothAll[indexToEditMoth].specieName = action.payload.specieName
                state.mothAll[indexToEditMoth].specieNameDotted = action.payload.specieNameDotted
                let newMothAll = state.mothAll
                state.mothUpdated = true
                return {...state, mothAll: newMothAll}
            }else{
                return {...state}
            }
        case actions.UPDATE_RECORD_MOTH_ERROR:
            return {
                ...state
            };
        case actions.UPDATE_RECORD_VEGETATION_SUCCESS:
            let indexToEditVegetation = state.vegetationAll.findIndex(veg=> veg.id ===action.payload.id)
            if(indexToEditVegetation!==-1){
                state.vegetationAll[indexToEditVegetation].specieName = action.payload.specieName
                state.vegetationAll[indexToEditVegetation].specieNameDotted = action.payload.specieNameDotted
                let newVegetationAll = state.vegetationAll
                state.vegetationUpdated = true
                return {...state, vegetationAll: newVegetationAll}
            }else{
                return {...state}
            }
        case actions.UPDATE_RECORD_VEGETATION_ERROR:
            return {
                ...state
            };
        case actions.RESET_APO_UPDATED:
            state.apoUpdated = false
            return {
                ...state
            };
        case actions.RESET_MOTH_UPDATED:
            state.mothUpdated = false
            return {
                ...state
            };
        case actions.RESET_VEGETATION_UPDATED:
            state.vegetationUpdated = false
            return {
                ...state
            };
        default:
            return state;
    }
}