const actions = {

    INITIALIZE_DETECTION: 'INITIALIZE_DETECTION',
    INITIALIZE_DETECTION_DETAILS : 'INITIALIZE_DETECTION_DETAILS',

    GET_ALL_DETECTION: 'GET_ALL_DETECTION',
    GET_ALL_DETECTION_SUCCESS: 'GET_ALL_DETECTION_SUCCESS',
    GET_ALL_DETECTION_ERROR: 'GET_ALL_DETECTION_ERROR',

    GET_DETECTION: 'GET_DETECTION',
    GET_DETECTION_SUCCESS: 'GET_DETECTION_SUCCESS',
    GET_DETECTION_ERROR: 'GET_DETECTION_ERROR',

    SAVE_DETECTION: 'SAVE_DETECTION',
    SAVE_DETECTION_SUCCESS: 'SAVE_DETECTION_SUCCESS',
    SAVE_DETECTION_ERROR: 'SAVE_DETECTION_ERROR',

    UPDATE_DETECTION: 'UPDATE_DETECTION',
    UPDATE_DETECTION_SUCCESS: 'UPDATE_DETECTION_SUCCESS',
    UPDATE_DETECTION_ERROR: 'UPDATE_DETECTION_ERROR',

    DELETE_DETECTION: 'DELETE_DETECTION',
    DELETE_DETECTION_SUCCESS: 'DELETE_DETECTION_SUCCESS',
    DELETE_DETECTION_ERROR: 'DELETE_DETECTION_ERROR',

    ASSIGN_VEGETABLE : "ASSIGN_VEGETABLE",
    ASSIGN_VEGETABLE_SUCCESS : "ASSIGN_VEGETABLE_SUCCESS",
    ASSIGN_VEGETABLE_ERROR : "ASSIGN_VEGETABLE_ERROR",

    ASSIGN_APOIDEA: "ASSIGN_APOIDEA",
    ASSIGN_APOIDEA_SUCCESS : "ASSIGN_APOIDEA_SUCCESS",
    ASSIGN_APOIDEA_ERROR : "ASSIGN_APOIDEA_ERROR",

    ASSIGN_MOTH :"ASSIGN_MOTH",
    ASSIGN_MOTH_SUCCESS : "ASSIGN_MOTH_SUCCESS",
    ASSIGN_MOTH_ERROR : "ASSIGN_MOTH_ERROR",

    GET_DETECTION_DETAILS : 'GET_DETECTION_DETAILS',
    GET_DETECTION_DETAILS_SUCCESS : 'GET_DETECTION_DETAILS_SUCCESS',
    GET_DETECTION_DETAILS_ERROR : 'GET_DETECTION_DETAILS_ERROR',

    SAVE_DETECTION_FROM_UPLOAD : "SAVE_DETECTION_FROM_UPLOAD",
    SAVE_DETECTION_FROM_UPLOAD_SUCCESS : "SAVE_DETECTION_FROM_UPLOAD_SUCCESS",
    SAVE_DETECTION_FROM_UPLOAD_ERROR : "SAVE_DETECTION_FROM_UPLOAD_ERROR",

    initializeDetection: () => ({
        type: actions.INITIALIZE_DETECTION
    }),

    initializeDetectionDetails: () => ({
        type: actions.INITIALIZE_DETECTION_DETAILS
    }),

    getAll: (pagination) => ({
        type: actions.GET_ALL_DETECTION,
        pagination: pagination
    }),

    getDetectionById: (entityToLoadId) => ({
        type: actions.GET_DETECTION,
        id: entityToLoadId,
    }),

    save: (data) => ({
        type: actions.SAVE_DETECTION,
        payload: data
    }),

    update: (data) => ({
        type: actions.UPDATE_DETECTION,
        payload: data,
    }),

    deleteData: (id) => ({
        type: actions.DELETE_DETECTION,
        id: id
    }),

    assignVegetable : (id, data)=> ({
        type: actions.ASSIGN_VEGETABLE,
        id: id,
        payload: data
    }),

    assignApoidea : (id, data)=>({
        type: actions.ASSIGN_APOIDEA,
        id: id,
        payload: data
    }),

    assignMoth : (id, data)=>({
        type: actions.ASSIGN_MOTH,
        id: id,
        payload: data
    }),

    getDetectionDetails : (data)=>({
        type: actions.GET_DETECTION_DETAILS,
        payload: data
    }),

    saveFromUpload : (data)=>({
        type: actions.SAVE_DETECTION_FROM_UPLOAD,
        payload: data
    }),
};
export default actions;
