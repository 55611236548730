import actions from './actions'
import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import SuperFetch from "@src/library/helpers/superFetch";
import {endpoints} from "@src/config/site.config";
import appAction from "@src/redux/app/actions";

export function* getInfo(){
    yield takeEvery(actions.GET_CURRENT_USER, function* (data){
        yield put({type:appAction.LOADING_TRUE});
        const{
            response,
            error
        } = yield call(SuperFetch.get, endpoints.userInfo , null, true)

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_CURRENT_USER_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_CURRENT_USER_SUCCESS,
                payload: error
            });
        }
    })
}

export function* updateUserData() {
    yield takeEvery(actions.UPDATE_USER_DATA, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.put, endpoints.updateUserData, data.payload, false, true);

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_USER_DATA_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_USER_DATA_ERROR,
                payload: error
            })
        }
    })
}


export function* deleteProfile() {
    yield takeEvery(actions.DELETE_PROFILE, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.get, endpoints.deleteProfile, null, true);
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.DELETE_PROFILE_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.DELETE_PROFILE_ERROR,
                payload: error
            })
        }
    })
}


export function* updateUserPassword() {
    yield takeEvery(actions.UPDATE_USER_PASSWORD, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.put, endpoints.updateUserPassword, data.payload, false, true);

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_USER_PASSWORD_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_USER_PASSWORD_ERROR,
                payload: error
            })
        }
    })
}

export function* searchByFirstNameLike() {
    yield takeEvery(actions.SEARCH_CUSTOM_USER_BY_NAME_LIKE, function* (data) {
        const {response, error} = yield call(SuperFetch.get, endpoints.customUserAutocomplete + "/" + data.name + "?role=" + data.role, null, true)
        if (response) {
            yield put({
                type: actions.SEARCH_CUSTOM_USER_BY_NAME_LIKE_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({
                type: actions.SEARCH_CUSTOM_USER_BY_NAME_LIKE_ERROR ,
                payload: error
            });
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getInfo),
        fork(updateUserData),
        fork(updateUserPassword),
        fork(deleteProfile),
        fork(searchByFirstNameLike)

    ]);
}
