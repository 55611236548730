import actions from './actions';
import createNotification from "@src/components/Notification";

const initState = {
    users: null,
    user: null,
    updateList: false,
    updatePage: false,
    entityToLoadId: null,
    backToList: false,
    autocomplete: null
};

export default function user(state = initState, action) {
    switch (action.type) {
        case actions.INITIALIZE_CUSTOM_USER:
            return {
                initState,
                updateList: true
            };
        case actions.GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload.value,
                totalResults: action.payload.totalResults,
                updateList: false,
                backToList: false
            };
        case actions.GET_USER:
            return {
                ...state,
                user: null,
                updatePage: false
            };
        case actions.GET_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.value,
                updatePage: true
            };
        case actions.SAVE_USER:
            return {
                ...state,
                updateList: false,
                backToList: false
            };
        case actions.SAVE_USER_SUCCESS:
            createNotification('info', 'Operazione avvenuta con successo')
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.UPDATE_USER:
            return {
                ...state,
                backToList: false,
                updateList: false
            };
        case actions.UPDATE_USER_SUCCESS:
            createNotification('info', 'Operazione avvenuta con successo')
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.DELETE_USER:
            return {
                ...state,
                updateList: false
            };
        case actions.DELETE_USER_SUCCESS:
            createNotification('info', 'Operazione avvenuta con successo')
            return {
                ...state,
                updateList: true
            };
        case actions.SEARCH_USER_BY_NAME_LIKE_SUCCESS:
            return{
                ...state,
                autocomplete: action.payload
            }
        default:
            return state;
    }
}
