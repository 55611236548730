import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {endpoints} from '@src/config/site.config'
import SuperFetch from '@src/library/helpers/superFetch';
import actions from './actions';
import appAction from "@src/redux/app/actions";
import {paginationQS} from "@src/library/utils/stringUtils";

export function* getAll() {

    yield takeEvery(actions.GET_ALL_TRANSEPTS, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.transepts + paginationQS(data.pagination), null, true)

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_TRANSEPTS_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_TRANSEPTS_ERROR,
                payload: error
            });
        }
    })
}

export function* get() {

    yield takeEvery(actions.GET_TRANSEPT, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.transepts + '/' + data.id, null, true)

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_TRANSEPT_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_TRANSEPT_ERROR,
                payload: error
            });
        }
    })
}

export function* save() {
    yield takeEvery(actions.SAVE_TRANSEPT, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.post, endpoints.transepts, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.SAVE_TRANSEPT_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.SAVE_TRANSEPT_ERROR,
                payload: error
            });
        }
    })
}

export function* updateTransept() {
    yield takeEvery(actions.UPDATE_TRANSEPT, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.put, endpoints.transepts + "/updateTransept", data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_TRANSEPT_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_TRANSEPT_ERROR,
                payload: error
            });
        }
    })
}

export function* updateField() {
    yield takeEvery(actions.UPDATE_FIELD, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.put, endpoints.transepts + "/updateField", data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_FIELD_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_FIELD_ERROR,
                payload: error
            });
        }
    })
}

export function* deleteData() {
    yield takeEvery(actions.DELETE_TRANSEPT, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.delete, endpoints.transepts + "/" + data.id, null, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.DELETE_TRANSEPT_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.DELETE_TRANSEPT_ERROR,
                payload: error
            });
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(getAll),
        fork(get),
        fork(save),
        fork(updateTransept),
        fork(updateField),
        fork(deleteData)
    ]);
}