export const PUBLIC_ROUTE = {
    LANDING: '/',
    PASSWORD_RECOVERY: '/passwordrecovery',
    RESET_PASSWORD: '/resetPassword/:oneTimeToken',
    REGISTRATION: '/signUp',
    PAGE_404: '/404',
    PAGE_500: '/500',
    AUTH0_CALLBACK: '/auth0loginCallback',
};

export const PRIVATE_ROUTE = {

    HOME: '',
    USERS: 'users',
    USER: 'user',
    COMPANIES: 'companies',
    COMPANY: 'company',
    TRANSEPT: 'transept',
    TRANSEPTS: 'transepts',
    DETECTION: 'detection',
    DETECTIONS: 'detections',
    DETECTION_DETAILS : 'detectionDetails',
    TRANSEPT_DETECTION: 'transeptDetection',
    BASE_DETECTIONS: 'baseDetections',
    BASE_DETECTION_DETAILS: 'baseDetectionDetails',
    VEGETATION_DETAILS: 'vegetationDetails',
    APOIDEA_DETAILS: 'apoideaDetails',
    MOTH_DETAILS: 'mothDetails',
    PROFILE: 'profile',
    LOADDETECTIONS: 'loadDetections',
    EDITSPECIES: 'editSpecies',
    EDITAPO: 'editApo',
    EDITMOTH: 'editMoth',
    EDITVEGETATION: 'editVegetation',

};
