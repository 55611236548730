import actions from './actions';
import createNotification from "@src/components/Notification";


const initState = {
    userInfo: null,
    updated: false
};

export default function userSettingsReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_CURRENT_USER_SUCCESS:
            return{
                ...state,
                userInfo: action.payload.value,
                updated: true
            };
        case actions.GET_CURRENT_USER:
            return{
                ...state,
                userInfo:null,
                updated: false,
            };
        case actions.UPDATE_USER_DATA_SUCCESS:
            createNotification('info', 'Aggiornamento eseguito')
            return {
                ...state,
                updated: action.value
            }
        case actions.UPDATE_USER_PASSWORD_SUCCESS:
            createNotification('info', 'Password modificata')
            return {
                ...state,
                updated: action.value
            }
        case actions.DELETE_PROFILE:
            return {
                ...state,
                deleted: false
            }
        case actions.DELETE_PROFILE_SUCCESS:
            return {
                ...state,
                deleted: true
            }
        case actions.SEARCH_CUSTOM_USER_BY_NAME_LIKE_SUCCESS:
            return{
                ...state,
                autocomplete: action.payload
            }
        default:
            return initState;
    }
}
