import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {endpoints} from '@src/config/site.config'
import {clearLocalStorage, getRolesFromLocalStorage, getToken, isTokenExpired} from '@src/library/helpers/utility';
import SuperFetch from '@src/library/helpers/superFetch';
import actions from './actions';
import {createBrowserHistory} from 'history';
import appAction from "@src/redux/app/actions";

const history = createBrowserHistory();

export function* loginRequest() {
    yield takeEvery(actions.LOGIN_REQUEST, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.post, endpoints.login, data.payload, false)

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.LOGIN_SUCCESS,
                token: response.access_token,
                roles: response.roles.join(','),
                id: response.id,
                expires_at: response.expires_at,
                username: response.username,
                rememberMe: data.payload.rememberMe,
            })
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.LOGIN_ERROR,
                payload: error
            })
        }
    })
}


export function* loginSuccess() {
    yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
        yield localStorage.setItem('rememberMe', payload.rememberMe ?? 'false');
        yield localStorage.setItem('timestamp', new Date().getTime().toString());
        yield localStorage.setItem('expires_at', payload.expires_at);
        yield localStorage.setItem('id_token', payload.token);
        yield localStorage.setItem('roles', payload.roles);
        yield localStorage.setItem('id', payload.id);
        yield localStorage.setItem('username', payload.username);

        yield put({
            type: actions.SET_ROLES,
            payload: payload.roles
        })
        if (payload.username) {
            yield localStorage.setItem('initials', payload.username.length > 1 ? payload.username.substr(0, 2) : payload.username)
        }

    });
}

export function* loginError() {
    yield takeEvery(actions.LOGIN_ERROR, function* () {
    });
}

export function* logout() {
    yield takeEvery(actions.LOGOUT, function* () {
        localStorage.clear();
        /*yield put({
            type: activityActions.INITIALIZE_ACTIVITY_STATE
        })*/
    });
}

export function* checkAuthorization() {

    yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
        if (isTokenExpired()) {
            if (localStorage.length > 0 && localStorage.getItem('rememberMe') === 'true') {
                yield put({
                    type: actions.REFRESH_TOKEN
                });
            } else {
                clearLocalStorage()
            }
        } else {
            const token = getToken().get('idToken');

            if (token) {
                yield put({
                    type: actions.LOGIN_SUCCESS,
                    id:localStorage.getItem('id'),
                    token: localStorage.getItem("id_token"),
                    roles: localStorage.getItem("roles"),
                    expires_at: localStorage.getItem("expires_at"),
                    rememberMe: localStorage.getItem("rememberMe"),
                    initials: localStorage.getItem("initials"),
                    username: localStorage.getItem("username")
                });
            }
        }
    });

}

export function* refreshToken() {

    yield takeEvery(actions.REFRESH_TOKEN, function* () {
        const {response, error} = yield call(SuperFetch.post, endpoints.refreshToken, null, false)
        if (response) {
            yield put({
                type: actions.LOGIN_SUCCESS,
                id:response.id,
                token: response.access_token,
                roles: getRolesFromLocalStorage().get('roles'),
                profile: 'Profile',
                expires_at: response.expires_at,
                username: response.username,
                timestamp: new Date().getTime().toString(),
                rememberMe: localStorage.getItem('rememberMe'),
            });
        } else {
            localStorage.clear();
            history.push('/')
        }
    })
}
/*
export function* getUserInfo() {
    yield takeEvery(actions.USER_INFO, function* (data) {
        const {response, error} = yield call(SuperFetch.get, endpoints.userInfo, null, true)

        if (response) {
            yield put({
                type: actions.USER_INFO_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({
                type: actions.USER_INFO_ERROR,
                payload: error
            })
        }
    })
}
 */
export function* firstQuoteSignUp() {
    yield takeEvery(actions.FIRST_QUOTE_SIGN_UP, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.post, endpoints.firstQuoteSignUp, data.payload, false, true)
        if (response) {

            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.FIRST_QUOTE_SIGN_UP_SUCCESS,
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.FIRST_QUOTE_SIGN_UP_ERROR,
                payload: error
            })
        }
    })
}

export function* dealerSignUp() {
    yield takeEvery(actions.DEALER_SIGN_UP_REQUEST, function* (data) {
        yield put({type: appAction.LOADING_TRUE});

        const {response, error} = yield call(SuperFetch.post, endpoints.dealerSignUp, data.payload, false)

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.DEALER_SIGN_UP_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.DEALER_SIGN_UP_ERROR,
                payload: error
            })
        }
    })
}

export function* truckerSignUp() {
    yield takeEvery(actions.TRUCKER_SIGN_UP_REQUEST, function* (data) {
        yield put({type: appAction.LOADING_TRUE});

        const {response, error} = yield call(SuperFetch.post, endpoints.truckerSignUp, data.payload, false)

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.TRUCKER_SIGN_UP_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.TRUCKER_SIGN_UP_ERROR,
                payload: error
            })
        }
    })
}

export function* recoverPassword() {
    yield takeEvery(actions.RECOVER_PASSWORD, function* (data) {
        yield put({type: appAction.LOADING_TRUE});

        const {response, error} = yield call(SuperFetch.post, endpoints.recoverPasswordByEmail, data.payload, false);

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.RECOVER_PASSWORD_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.RECOVER_PASSWORD_ERROR,
                payload: error
            })
        }
    })
}

export function* resetPassword() {
    yield takeEvery(actions.RESET_PASSWORD, function* (data) {
        yield put({type: appAction.LOADING_TRUE});

        const {response, error} = yield call(SuperFetch.post, endpoints.resetPassword, data.payload, false);

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.RESET_PASSWORD_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.RESET_PASSWORD_ERROR,
                payload: error
            })
        }
    })
}

export function* signUp() {
    yield takeEvery(actions.SIGN_UP, function* (data) {
        yield put({type: appAction.LOADING_TRUE})

        const {response, error} = yield call(SuperFetch.post, endpoints.register, data.payload, false);

        yield put({type: appAction.LOADING_FALSE});

        if(response){
            yield put({
                type: actions.SIGN_UP_SUCCESS,
                payload: response
            })
        } else {
            yield put({
                type: actions.SIGN_UP_ERROR,
                payload: error
            })
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(checkAuthorization),
        fork(loginRequest),
        fork(loginSuccess),
        fork(loginError),
        fork(logout),
        fork(dealerSignUp),
        fork(truckerSignUp),
        fork(refreshToken),
        fork(recoverPassword),
        fork(resetPassword),
        fork(firstQuoteSignUp),
        //fork(getUserInfo),
        fork(signUp),
    ]);
}
