import {notification} from 'antd';

const createNotification = (type, message, description) => {
    notification[type]({
        message,
        description,
    });
};

export const customCreateNotification = (type, argumentsObject) => {
    notification[type](
        argumentsObject
    );
};

export default createNotification;

