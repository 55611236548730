import createNotification from "../../components/Notification";
import LanguageHelper from "./languageHelper";
import {isTokenExpired} from "./utility";
import {endpoints} from "../../config/site.config";
import {objectToFormData} from '@src/library/utils/objectToFormData';
import {randomFileName} from "@src/library/utils/stringUtils";

// const headerType= {
//   base: 1,
//   authenticate: 2,
//   file: 3
// }

const customHeader = () => ({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('id_token') || undefined,
});

const fileHeader = () => ({

    ...localStorage.getItem('id_token') && {Authorization: 'Bearer ' + localStorage.getItem('id_token')},
});

const fileHeaderNull = () => ({
    'Content-Type': 'multipart/form-data; boundary=0',
    ...localStorage.getItem('id_token') && {Authorization: 'Bearer ' + localStorage.getItem('id_token')},
});


const baseHeader = () => ({
    'Content-Type': 'application/json',
    Accept: 'application/json'
})

const parseValue = (valueToParse) => {

    if (valueToParse instanceof Array) {
        if (valueToParse[0] instanceof Object) {
            let asd = []
            valueToParse.forEach(value => {
                asd.push(JSON.stringify(value))
            })
            return (asd)
        } else {
            return valueToParse.toString()
        }
    } else if (valueToParse instanceof Object) {
        return JSON.stringify(valueToParse)
    } else
        return valueToParse ? valueToParse.toString() : ""


}

const base = (method, url, data = {}, authenticate = false, postRequestWithFormData = false, errorTypesToNotNotify, isFile = false) => {
    let requestConfig = {
        method,
        credentials: 'include'
    };
    if (authenticate) requestConfig.headers = customHeader();
    else requestConfig.headers = baseHeader();
    if (data) {
        if (postRequestWithFormData) {

            requestConfig.body = objectToFormData(data, {indices: true});
            requestConfig.headers = fileHeader()
        } else {
            requestConfig.body = JSON.stringify(data)
        }
        /*if(isFile){
            requestConfig.headers = fileDownloadHeader();
        }*/
    }

    if (authenticate && isTokenExpired()) {
        return fetch(endpoints.refreshToken, requestConfig)
            .then(response => response.json())
            .then(json => {
                localStorage.setItem("id_token", json.access_token)
                localStorage.setItem("expires_at", json.expires_at)
                localStorage.setItem("timestamp", new Date().getTime().toString())

                if (json.access_token != null) {
                    return _doFetch(method, url, data, authenticate, errorTypesToNotNotify, isFile, requestConfig)
                } else {
                    return {}
                }
            })
            .catch(error => ({
                error: error
            }));
    } else {
        return _doFetch(method, url, data, authenticate, errorTypesToNotNotify, isFile, requestConfig)
    }

};

function _doFetch(method, url, data, authenticate, errorTypesToNotNotify, isFile, requestConfig) {
    return fetch(url, requestConfig)
        .then(response => {
            if (!isFile) {
                return handleResponse(response, errorTypesToNotNotify)
            } else {
                console.log(response)
                response.blob().then(blob => {
                    if(data && data.name) {
                        var url = window.URL.createObjectURL(blob);
                        var a = document.createElement('a');
                        a.href = url;
                        //var fileName = data.name.replace(/^.*[\\\/]/,"")
                        var fileName
                        try {
                            fileName = response.headers.get('Content-disposition').split(";")[1].split("\"")[1]
                        } catch(e) {
                            fileName = randomFileName(10)
                        }
                        a.download = fileName;
                        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                        a.click();
                        a.remove();  //afterwards we remove the element again
                    } else {
                        createNotification("error", "File name not provided")
                    }
                })
                return {}
            }
        })
        .catch(error => ({
            error: error
        }));
}

async function handleResponse(response, errorTypesToNotNotify) {
    let errorMessage;
    let json;
    if(response.status === 400) {
        json = await response.json();
        if(json) {
            var messageToShow = json.message
            if(json.validationErrors && json.validationErrors.length > 0) {
                json.validationErrors.forEach(validationError => messageToShow += " - " + validationError.field)
            }
            createNotification("error", messageToShow)
        }
    }

    if (response.status === 401) {
        createNotification("error", LanguageHelper.formatMessage({id: "serverResponse.401"}))
    } else if (response && response.status && (response.status !== 200 && response.status !== 206)) {
        json = await response.json();
        if (response.status) {
            errorMessage = "An error has occurred. Error code: " + response.status;
            if (response.statusText) {
                errorMessage += "\nError message: " + errorMessage
            }
        }
    } else {
        if (response.status === 206) {
            json = response;
        } else {
            json = await response.json();
            if (json && json.errorType && json.errorType !== 0) {
                if (json.message) {
                    errorMessage = json.message
                } else {
                    errorMessage = "An error has occurred"
                }
            }
        }
    }


    if (errorMessage) {


        let errors = json && json.value && json.value.errors ? json.value.errors : null
        let errorResponse = {error: errorMessage, value: errors}
        //if an error type is provided by the backend, check if this error type has to be not notified
        if (json && json.errorType) {
            errorResponse.errorType = json.errorType

            if (!(errorTypesToNotNotify && errorTypesToNotNotify.indexOf(json.errorType) !== -1)) {

                if (errors && errors.length > 0) {
                    errors.map(e => createNotification('error', e.message)
                    )
                } else {
                    createNotification('error', errorMessage);
                }
            }
        } else {
            if (errors && errors.length > 0) {
                errors.map(e => createNotification('error', e.message)
                )
            } else {
                createNotification('error', errorMessage);
            }
        }
        return errorResponse
    } else {
        return {response: json}
    }
}

const SuperFetch = {};
['get', 'post', 'put', 'delete', 'PATCH'].forEach(method => {
    SuperFetch[method] = base.bind(null, method);
});
export default SuperFetch;
