import actions from './actions';
import createNotification from "@src/components/Notification";

const initState = {
    transept: null,
    transepts: null,
    updateList: false,
    updatePage: false,
    entityToLoadId: null,
    backToList: false,
    autocomplete: null
};

export default function transept(state = initState, action) {
    switch (action.type) {
        case actions.INITIALIZE_TRANSEPT:
            return {
                initState,
                updateList: true
            };
        case actions.GET_ALL_TRANSEPTS_SUCCESS:
            return {
                ...state,
                transepts: action.payload.value,
                totalResults: action.payload.totalResults,
                updateList: false,
                backToList: false
            };
        case actions.GET_TRANSEPT:
            return {
                ...state,
                transept: null,
                updatePage: false
            };
        case actions.GET_TRANSEPT_SUCCESS:
            return {
                ...state,
                transept: action.payload.value,
                updatePage: true
            };
        case actions.SAVE_TRANSEPT:
            return {
                ...state,
                updateList: false,
                backToList: false
            };
        case actions.SAVE_TRANSEPT_SUCCESS:
            createNotification('info', 'Operazione avvenuta con successo')
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.UPDATE_TRANSEPT:
            return {
                ...state,
                backToList: false,
                updateList: false
            };
        case actions.UPDATE_TRANSEPT_SUCCESS:
            createNotification('info', 'Operazione avvenuta con successo')
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.UPDATE_FIELD:
            return {
                ...state,
                backToList: false,
                updateList: false
            };
        case actions.UPDATE_FIELD_SUCCESS:
            createNotification('info', 'Operazione avvenuta con successo')
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.DELETE_TRANSEPT:
            return {
                ...state,
                updateList: false
            };
        case actions.DELETE_TRANSEPT_SUCCESS:
            createNotification('info', 'Operazione avvenuta con successo')
            return {
                ...state,
                updateList: true
            };
        default:
            return state;
    }
}
