import React from 'react';
import {Provider} from 'react-redux';
import GlobalStyles from '@src/assets/styles/globalStyle';
import {store} from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import {addResponseMessage} from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';


function App() {

    React.useEffect(() => {
        addResponseMessage('Benvenuti nella chat di assistenza! Lascia un messaggio ti risponderemo quanto prima');
    }, []);

    const handleNewUserMessage = (newMessage) => {
        console.log(`New message incoming! ${newMessage}`);
        // Now send the message throught the backend API
    };

    return (
        <Provider store={store}>
            <AppProvider>
                <>
                    <GlobalStyles/>
                    <Routes/>
                    {/*   <Widget
                        title="Chat di assistenza"
                        subtitle=""
                        senderPlaceHolder="Scrivi un messaggio"
                        handleNewUserMessage={handleNewUserMessage}/>*/}
                </>
            </AppProvider>
        </Provider>
    );
}

Boot()
    .then(() => App())
    .catch(error => console.error(error));
export default App;

