const actions = {

    GET_CURRENT_USER: 'GET_CURRENT_USER',
    GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_SUCCESS',
    GET_CURRENT_USER_ERROR: 'GET_CURRENT_USER_ERROR',

    UPDATE_USER_DATA: 'UPDATE_USER_DATA',
    UPDATE_USER_DATA_SUCCESS: 'UPDATE_USER_DATA_SUCCESS',
    UPDATE_USER_DATA_ERROR: 'UPDATE_USER_DATA_ERROR',

    UPDATE_USER_PASSWORD: 'UPDATE_USER_PASSWORD',
    UPDATE_USER_PASSWORD_SUCCESS: 'UPDATE_USER_PASSWORD_SUCCESS',
    UPDATE_USER_PASSWORD_ERROR: 'UPDATE_USER_PASSWORD_ERROR',

    DELETE_PROFILE: 'DELETE_PROFILE',
    DELETE_PROFILE_SUCCESS: 'DELETE_PROFILE_SUCCESS',
    DELETE_PROFILE_ERROR: 'DELETE_PROFILE_ERROR',

    SEARCH_CUSTOM_USER_BY_NAME_LIKE: 'SEARCH_CUSTOM_USER_BY_NAME_LIKE',
    SEARCH_CUSTOM_USER_BY_NAME_LIKE_SUCCESS: 'SEARCH_CUSTOM_USER_BY_NAME_LIKE_SUCCESS',
    SEARCH_CUSTOM_USER_BY_NAME_LIKE_ERROR: 'SEARCH_CUSTOM_USER_BY_NAME_LIKE_ERROR',

    getUserInfo: () => ({
        type: actions.GET_CURRENT_USER,
    }),

    updateUserData: (data) => ({
        type: actions.UPDATE_USER_DATA,
        payload: data
    }),

    updateUserPassword: (data) => ({
        type: actions.UPDATE_USER_PASSWORD,
        payload: data
    }),

    deleteProfile: () => ({
        type: actions.DELETE_PROFILE,
    }),

    searchCustomUserByNameLike: (nameSearch, role) => ({
        type: actions.SEARCH_CUSTOM_USER_BY_NAME_LIKE,
        name: nameSearch,
    })
};

export default actions;





