import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {endpoints} from '@src/config/site.config'
import SuperFetch from '@src/library/helpers/superFetch';
import actions from './actions';
import appAction from "@src/redux/app/actions";
import {paginationQS} from "@src/library/utils/stringUtils";

export function* getAll() {

    yield takeEvery(actions.GET_ALL_USERS, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.users + paginationQS(data.pagination), null, true)

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_USERS_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_USERS_ERROR,
                payload: error
            });
        }
    })
}

export function* get() {

    yield takeEvery(actions.GET_USER, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.users + '/' + data.id, null, true)

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_USER_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_USER_ERROR,
                payload: error
            });
        }
    })
}

export function* save() {
    yield takeEvery(actions.SAVE_USER, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.post, endpoints.users, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.SAVE_USER_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.SAVE_USER_ERROR,
                payload: error
            });
        }
    })
}

export function* update() {
    yield takeEvery(actions.UPDATE_USER, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.put, endpoints.users + "/" + data.payload.id, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_USER_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_USER_ERROR,
                payload: error
            });
        }
    })
}

export function* deleteData() {
    yield takeEvery(actions.DELETE_USER, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.delete, endpoints.users + "/" + data.id, null, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.DELETE_USER_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.DELETE_USER_ERROR,
                payload: error
            });
        }
    })
}

export function* searchByUserNameLike() {
    yield takeEvery(actions.SEARCH_USER_BY_NAME_LIKE, function* (data) {
        //const {response, error} = yield call(SuperFetch.get, endpoints.customUserAutocomplete + "/" + data.name, null, true)
        const {response, error} = yield call(SuperFetch.get, endpoints.customUserAutocomplete + "/" + data.name + "?role=" + data.role, null, true)
        if (response) {
            yield put({
                type: actions.SEARCH_USER_BY_NAME_LIKE_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({
                type: actions.SEARCH_USER_BY_NAME_LIKE_ERROR,
                payload: error
            });
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getAll),
        fork(get),
        fork(save),
        fork(update),
        fork(deleteData),
        fork(searchByUserNameLike),
    ]);
}