const actions = {
    INIT: 'INIT',

    CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGOUT: 'LOGOUT',
    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',

    SET_ROLES: 'SET_ROLES',

    DEALER_SIGN_UP_REQUEST: 'DEALER_SIGN_UP_REQUEST',
    DEALER_SIGN_UP_SUCCESS: 'DEALER_SIGN_UP_SUCCESS',
    DEALER_SIGN_UP_ERROR: 'DEALER_SIGN_UP_ERROR',
    TRUCKER_SIGN_UP_REQUEST: 'TRUCKER_SIGN_UP_REQUEST',
    TRUCKER_SIGN_UP_SUCCESS: 'TRUCKER_SIGN_UP_SUCCESS',
    TRUCKER_SIGN_UP_ERROR: 'TRUCKER_SIGN_UP_ERROR',
    FIRST_QUOTE_SIGN_UP: 'FIRST_QUOTE_SIGN_UP',
    FIRST_QUOTE_SIGN_UP_SUCCESS: 'FIRST_QUOTE_SIGN_UP_SUCCESS',
    FIRST_QUOTE_SIGN_UP_ERROR: 'FIRST_QUOTE_SIGN_UP_ERROR',

    RECOVER_PASSWORD: 'RECOVER_PASSWORD',
    RECOVER_PASSWORD_SUCCESS: 'RECOVER_PASSWORD_SUCCESS',
    RECOVER_PASSWORD_ERROR: 'RECOVER_PASSWORD_ERROR',

    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',

    USER_INFO: 'USER_INFO',
    USER_INFO_SUCCESS: 'USER_INFO_SUCCESS',
    USER_INFO_ERROR: 'USER_INFO_ERROR',

    REFRESH_TOKEN: 'REFRESH_TOKEN',

    SIGN_UP: 'SIGN_UP',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_UP_ERROR: 'SIGN_UP_ERROR',

    checkAuthorization: () => ({type: actions.CHECK_AUTHORIZATION}),

    init: () => ({
        type: actions.INIT
    }),

    login: (data) => ({
        type: actions.LOGIN_REQUEST,
        payload: data
    }),

    logout: () => ({
        type: actions.LOGOUT,
    }),

    signUp: (data) => ({
        type: actions.SIGN_UP,
        payload: data,
    }),

    firstQuoteSignUp: (data) => ({
        type: actions.FIRST_QUOTE_SIGN_UP,
        payload: data,
    }),

    dealerSignUp: (data) => ({
        type: actions.DEALER_SIGN_UP_REQUEST,
        payload: data,
    }),

    truckerSignUp: (data) => ({
        type: actions.TRUCKER_SIGN_UP_REQUEST,
        payload: data,
    }),

    recoverPassword: (data) => ({
        type: actions.RECOVER_PASSWORD,
        payload: data,
    }),

    resetPassword: (data) => ({
        type: actions.RESET_PASSWORD,
        payload: data,
    }),

};
export default actions;
