import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {endpoints} from '@src/config/site.config'
import SuperFetch from '@src/library/helpers/superFetch';
import actions from './actions';

export function* searchApoideaByNameLike() {
    yield takeEvery(actions.SEARCH_APOIDEA_BY_NAME_LIKE, function* (data) {
        const {response, error} = yield call(SuperFetch.get, endpoints.apoideaAutocomplete + "/" + data.name+"?apoideaType="+data.apoideaType, null, true)
        if (response) {
            yield put({
                type: actions.SEARCH_APOIDEA_BY_NAME_LIKE_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({
                type: actions.SEARCH_APOIDEA_BY_NAME_LIKE_ERROR,
                payload: error
            });
        }
    })
}

export function* searchMothByNameLike() {
    yield takeEvery(actions.SEARCH_MOTH_BY_NAME_LIKE, function* (data) {
        const {response, error} = yield call(SuperFetch.get, endpoints.mothAutocomplete + "/" + data.name, null, true)
        if (response) {
            yield put({
                type: actions.SEARCH_MOTH_BY_NAME_LIKE_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({
                type: actions.SEARCH_MOTH_BY_NAME_LIKE_ERROR,
                payload: error
            });
        }
    })
}

export function* searchFloraByNameLike() {
    yield takeEvery(actions.SEARCH_FLORA_BY_NAME_LIKE, function* (data) {
        const {response, error} = yield call(SuperFetch.get, endpoints.floraAutocomplete + "/" + data.name, null, true)
        if (response) {
            yield put({
                type: actions.SEARCH_FLORA_BY_NAME_LIKE_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({
                type: actions.SEARCH_FLORA_BY_NAME_LIKE_ERROR,
                payload: error
            });
        }
    })
}

export function* getAllMoth() {
    yield takeEvery(actions.GET_ALL_MOTH, function* (data) {
        const {response, error} = yield call(SuperFetch.get, endpoints.getAllMoth, null, true)
        if (response) {
            yield put({
                type: actions.GET_ALL_MOTH_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({
                type: actions.GET_ALL_MOTH_ERROR,
                payload: error
            });
        }
    })
}

export function* getAllApoidea() {
    yield takeEvery(actions.GET_ALL_APOIDEA, function* (data) {
        const {response, error} = yield call(SuperFetch.get, endpoints.getAllApoidea, null, true)
        if (response) {
            yield put({
                type: actions.GET_ALL_APOIDEA_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({
                type: actions.GET_ALL_APOIDEA_ERROR,
                payload: error
            });
        }
    })
}

export function* getAllVegetation() {
    yield takeEvery(actions.GET_ALL_VEGETATION, function* (data) {
        const {response, error} = yield call(SuperFetch.get, endpoints.getAllVegetation, null, true)
        if (response) {
            yield put({
                type: actions.GET_ALL_VEGETATION_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({
                type: actions.GET_ALL_VEGETATION_ERROR,
                payload: error
            });
        }
    })
}

export function* updateRecordApoidea() {
    yield takeEvery(actions.UPDATE_RECORD_APOIDEA, function* (data) {
        const {response, error} = yield call(SuperFetch.post, endpoints.updateApoidea, data.payload,true)
        if (response) {
            yield put({
                type: actions.UPDATE_RECORD_APOIDEA_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({
                type: actions.UPDATE_RECORD_APOIDEA_ERROR,
                payload: error
            });
        }
    })
}

export function* updateRecordMoth() {
    yield takeEvery(actions.UPDATE_RECORD_MOTH, function* (data) {
        const {response, error} = yield call(SuperFetch.post, endpoints.updateMoth, data.payload,true)
        if (response) {
            yield put({
                type: actions.UPDATE_RECORD_MOTH_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({
                type: actions.UPDATE_RECORD_MOTH_ERROR,
                payload: error
            });
        }
    })
}

export function* updateRecordVegetation() {
    yield takeEvery(actions.UPDATE_RECORD_VEGETATION, function* (data) {
        const {response, error} = yield call(SuperFetch.post, endpoints.updateVegetation, data.payload,true)
        if (response) {
            yield put({
                type: actions.UPDATE_RECORD_VEGETATION_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({
                type: actions.UPDATE_RECORD_VEGETATION_ERROR,
                payload: error
            });
        }
    })
}


export default function* rootSaga() {
    yield all([
        fork(searchApoideaByNameLike),
        fork(searchMothByNameLike),
        fork(searchFloraByNameLike),
        fork(getAllMoth),
        fork(getAllApoidea),
        fork(getAllVegetation),
        fork(updateRecordApoidea),
        fork(updateRecordMoth),
        fork(updateRecordVegetation)
    ]);
}