import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {endpoints} from '@src/config/site.config'
import SuperFetch from '@src/library/helpers/superFetch';
import actions from './actions';
import appAction from "@src/redux/app/actions";
import {paginationQS} from "@src/library/utils/stringUtils";

export function* getAll() {

    yield takeEvery(actions.GET_ALL_COMPANIES, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.companies + paginationQS(data.pagination), null, true)

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_COMPANIES_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_COMPANIES_ERROR,
                payload: error
            });
        }
    })
}

export function* get() {

    yield takeEvery(actions.GET_COMPANY, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.companies + '/' + data.id, null, true)

        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_COMPANY_SUCCESS,
                payload: response
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_COMPANY_ERROR,
                payload: error
            });
        }
    })
}

export function* save() {
    yield takeEvery(actions.SAVE_COMPANY, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.post, endpoints.companies, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.SAVE_COMPANY_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.SAVE_COMPANY_ERROR,
                payload: error
            });
        }
    })
}

export function* update() {
    yield takeEvery(actions.UPDATE_COMPANY, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.put, endpoints.companies + "/" + data.payload.id, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_COMPANY_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_COMPANY_ERROR,
                payload: error
            });
        }
    })
}

export function* deleteData() {
    yield takeEvery(actions.DELETE_COMPANY, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {response, error} = yield call(SuperFetch.delete, endpoints.companies + "/" + data.id, null, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.DELETE_COMPANY_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.DELETE_COMPANY_ERROR,
                payload: error
            });
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getAll),
        fork(get),
        fork(save),
        fork(update),
        fork(deleteData)
    ]);
}