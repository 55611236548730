import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {endpoints} from '@src/config/site.config'
import SuperFetch from '@src/library/helpers/superFetch';
import actions from './actions';
import appAction from "@src/redux/app/actions";

export function* getAll() {

    yield takeEvery(actions.GET_ALL_BASE_DETECTIONS, function* (data) {
        yield put({type: appAction.LOADING_TRUE});

        const params=(data.payload === "validated")?'?isValidated=true':""

        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.baseDetections + params, null, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_BASE_DETECTIONS_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_ALL_BASE_DETECTIONS_ERROR,
                payload: error
            });
        }
    })
}

export function* getVegetationDetection() {

    yield takeEvery(actions.GET_VEGETATION_DETECTION, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.vegetationDetections+ '/' + data.payload, null, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_VEGETATION_DETECTION_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_VEGETATION_DETECTION_ERROR,
                payload: error
            });
        }
    })
}

export function* getApoideaDetection() {

    yield takeEvery(actions.GET_APOIDEA_DETECTION, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.apoideaDetections+ '/' + data.payload, null, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_APOIDEA_DETECTION_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_APOIDEA_DETECTION_ERROR,
                payload: error
            });
        }
    })
}

export function* getMothDetection() {

    yield takeEvery(actions.GET_MOTH_DETECTION, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.get, endpoints.mothDetections+ '/' + data.payload, null, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_MOTH_DETECTION_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.GET_MOTH_DETECTION_ERROR,
                payload: error
            });
        }
    })
}

export function* updateVegetationRecord() {

    yield takeEvery(actions.UPDATE_VEGETATION_RECORD, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.put, endpoints.vegetationRecords+ '/' + data.id, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_VEGETATION_RECORD_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_VEGETATION_RECORD_ERROR,
                payload: error
            });
        }
    })
}

export function* updateApoideaRecord() {

    yield takeEvery(actions.UPDATE_APOIDEA_RECORD, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.put, endpoints.apoideaRecords+ '/' + data.id, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_APOIDEA_RECORD_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_APOIDEA_RECORD_ERROR,
                payload: error
            });
        }
    })
}

export function* updateMothRecord() {

    yield takeEvery(actions.UPDATE_MOTH_RECORD, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.put, endpoints.mothRecords+ '/' + data.id, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_MOTH_RECORD_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_MOTH_RECORD_ERROR,
                payload: error
            });
        }
    })
}

export function* validateDetection() {

    yield takeEvery(actions.VALIDATE_DETECTION, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.PATCH, endpoints.baseDetections+ '/validate/' + data.id+"?detectionType="+data.detectionType, null, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.VALIDATE_DETECTION_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.VALIDATE_DETECTION_ERROR,
                payload: error
            });
        }
    })
}

export function* updateApoideaDetection() {

    yield takeEvery(actions.UPDATE_APOIDEA_DETECTION, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.put, endpoints.apoideaDetections+ '/' + data.id, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_APOIDEA_DETECTION_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_APOIDEA_DETECTION_ERROR,
                payload: error
            });
        }
    })
}

export function* updateMothDetection() {

    yield takeEvery(actions.UPDATE_MOTH_DETECTION, function* (data) {
        yield put({type: appAction.LOADING_TRUE});
        const {
            response,
            error
        } = yield call(SuperFetch.put, endpoints.mothDetections+ '/' + data.id, data.payload, true)
        if (response) {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_MOTH_DETECTION_SUCCESS,
                payload: response.value
            });
        } else {
            yield put({type: appAction.LOADING_FALSE});
            yield put({
                type: actions.UPDATE_MOTH_DETECTION_ERROR,
                payload: error
            });
        }
    })
}



export default function* rootSaga() {
    yield all([
        fork(getAll),
        fork(getVegetationDetection),
        fork(getApoideaDetection),
        fork(getMothDetection),
        fork(updateVegetationRecord),
        fork(updateApoideaRecord),
        fork(updateMothRecord),
        fork(validateDetection),
        fork(updateApoideaDetection),
        fork(updateMothDetection),
    ]);
}