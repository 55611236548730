const actions = {

    SEARCH_APOIDEA_BY_NAME_LIKE: 'SEARCH_APOIDEA_BY_NAME_LIKE',
    SEARCH_APOIDEA_BY_NAME_LIKE_SUCCESS: 'SEARCH_APOIDEA_BY_NAME_LIKE_SUCCESS',
    SEARCH_APOIDEA_BY_NAME_LIKE_ERROR: 'SEARCH_APOIDEA_BY_NAME_LIKE_ERROR',

    SEARCH_FLORA_BY_NAME_LIKE: 'SEARCH_FLORA_BY_NAME_LIKE',
    SEARCH_FLORA_BY_NAME_LIKE_SUCCESS: 'SEARCH_FLORA_BY_NAME_LIKE_SUCCESS',
    SEARCH_FLORA_BY_NAME_LIKE_ERROR: 'SEARCH_FLORA_BY_NAME_LIKE_ERROR',

    SEARCH_MOTH_BY_NAME_LIKE: 'SEARCH_MOTH_BY_NAME_LIKE',
    SEARCH_MOTH_BY_NAME_LIKE_SUCCESS: 'SEARCH_MOTH_BY_NAME_LIKE_SUCCESS',
    SEARCH_MOTH_BY_NAME_LIKE_ERROR: 'SEARCH_MOTH_BY_NAME_LIKE_ERROR',

    GET_ALL_MOTH : "GET_ALL_MOTH",
    GET_ALL_MOTH_SUCCESS :"GET_ALL_MOTH_SUCCESS",
    GET_ALL_MOTH_ERROR :"GET_ALL_MOTH_ERROR",

    GET_ALL_APOIDEA : "GET_ALL_APOIDEA",
    GET_ALL_APOIDEA_SUCCESS :"GET_ALL_APOIDEA_SUCCESS",
    GET_ALL_APOIDEA_ERROR :"GET_ALL_APOIDEA_ERROR",

    GET_ALL_VEGETATION : "GET_ALL_VEGETATION",
    GET_ALL_VEGETATION_SUCCESS :"GET_ALL_VEGETATION_SUCCESS",
    GET_ALL_VEGETATION_ERROR :"GET_ALL_VEGETATION_ERROR",

    UPDATE_RECORD_APOIDEA : "UPDATE_RECORD_APOIDEA",
    UPDATE_RECORD_APOIDEA_SUCCESS : "UPDATE_RECORD_APOIDEA_SUCCESS",
    UPDATE_RECORD_APOIDEA_ERROR : "UPDATE_RECORD_APOIDEA_ERROR",

    UPDATE_RECORD_MOTH : "UPDATE_RECORD_MOTH",
    UPDATE_RECORD_MOTH_SUCCESS : "UPDATE_RECORD_MOTH_SUCCESS",
    UPDATE_RECORD_MOTH_ERROR : "UPDATE_RECORD_MOTH_ERROR",

    UPDATE_RECORD_VEGETATION : "UPDATE_RECORD_VEGETATION",
    UPDATE_RECORD_VEGETATION_SUCCESS : "UPDATE_RECORD_VEGETATION_SUCCESS",
    UPDATE_RECORD_VEGETATION_ERROR : "UPDATE_RECORD_VEGETATION_ERROR",

    RESET_APO_UPDATED : "RESET_APO_UPDATED",
    RESET_MOTH_UPDATED : "RESET_MOTH_UPDATED",
    RESET_VEGETATION_UPDATED : "RESET_VEGETATION_UPDATED",


    searchApoideaByNameLike: (name,apoideaType) => ({
        type: actions.SEARCH_APOIDEA_BY_NAME_LIKE,
        name: name,
        apoideaType:apoideaType
    }),

    searchFloraByNameLike: (name) => ({
        type: actions.SEARCH_FLORA_BY_NAME_LIKE,
        name: name
    }),
    searchMothByNameLike: (name) => ({
        type: actions.SEARCH_MOTH_BY_NAME_LIKE,
        name: name
    }),
    getAllMoth: () => ({
        type: actions.GET_ALL_MOTH,
        data:null
    }),
    getAllApoidea: () => ({
        type: actions.GET_ALL_APOIDEA,
        data:null
    }),
    getAllVegetation: () => ({
        type: actions.GET_ALL_VEGETATION,
        data:null
    }),
    updateRecordApoidea: (data) => ({
        type: actions.UPDATE_RECORD_APOIDEA,
        payload:data
    }),
    updateRecordMoth: (data) => ({
        type: actions.UPDATE_RECORD_MOTH,
        payload:data
    }),
    updateRecordVegetation: (data) => ({
        type: actions.UPDATE_RECORD_VEGETATION,
        payload:data
    }),
    resetApoUpdated: () => ({
        type: actions.RESET_APO_UPDATED
    }),
    resetMothUpdated: () => ({
        type: actions.RESET_MOTH_UPDATED
    }),
    resetVegetationUpdated: () => ({
        type: actions.RESET_VEGETATION_UPDATED
    })


};
export default actions;
