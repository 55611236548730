const actions = {
    INITIALIZE_BASE_DETECTIONS: 'INITIALIZE_BASE_DETECTIONS',
    INITIALIZE_VEGETATION_DETECTION: 'INITIALIZE_VEGETATION_DETECTION',
    INITIALIZE_APOIDEA_DETECTION: 'INITIALIZE_APOIDEA_DETECTION',
    INITIALIZE_MOTH_DETECTION: 'INITIALIZE_MOTH_DETECTION',

    GET_ALL_BASE_DETECTIONS: 'GET_ALL_BASE_DETECTIONS',
    GET_ALL_BASE_DETECTIONS_SUCCESS: 'GET_ALL_BASE_DETECTIONS_SUCCESS',
    GET_ALL_BASE_DETECTIONS_ERROR: 'GET_ALL_BASE_DETECTIONS_ERROR',

    GET_VEGETATION_DETECTION: 'GET_VEGETATION_DETECTION',
    GET_VEGETATION_DETECTION_SUCCESS: 'GET_VEGETATION_DETECTION_SUCCESS',
    GET_VEGETATION_DETECTION_ERROR: 'GET_VEGETATION_DETECTION_ERROR',

    GET_APOIDEA_DETECTION: 'GET_APOIDEA_DETECTION',
    GET_APOIDEA_DETECTION_SUCCESS: 'GET_APOIDEA_DETECTION_SUCCESS',
    GET_APOIDEA_DETECTION_ERROR: 'GET_APOIDEA_DETECTION_ERROR',

    GET_MOTH_DETECTION: 'GET_MOTH_DETECTION',
    GET_MOTH_DETECTION_SUCCESS: 'GET_MOTH_DETECTION_SUCCESS',
    GET_MOTH_DETECTION_ERROR: 'GET_MOTH_DETECTION_ERROR',

    UPDATE_VEGETATION_RECORD: 'UPDATE_VEGETATION_RECORD',
    UPDATE_VEGETATION_RECORD_SUCCESS: 'UPDATE_VEGETATION_RECORD_SUCCESS',
    UPDATE_VEGETATION_RECORD_ERROR: 'UPDATE_VEGETATION_RECORD_ERROR',

    UPDATE_APOIDEA_RECORD: 'UPDATE_APOIDEA_RECORD',
    UPDATE_APOIDEA_RECORD_SUCCESS: 'UPDATE_APOIDEA_RECORD_SUCCESS',
    UPDATE_APOIDEA_RECORD_ERROR: 'UPDATE_APOIDEA_RECORD_ERROR',

    UPDATE_APOIDEA_DETECTION: 'UPDATE_APOIDEA_DETECTION',
    UPDATE_APOIDEA_DETECTION_SUCCESS: 'UPDATE_APOIDEA_DETECTION_SUCCESS',
    UPDATE_APOIDEA_DETECTION_ERROR: 'UPDATE_APOIDEA_DETECTION_ERROR',

    UPDATE_MOTH_RECORD: 'UPDATE_MOTH_RECORD',
    UPDATE_MOTH_RECORD_SUCCESS: 'UPDATE_MOTH_RECORD_SUCCESS',
    UPDATE_MOTH_RECORD_ERROR: 'UPDATE_MOTH_RECORD_ERROR',

    UPDATE_MOTH_DETECTION: 'UPDATE_MOTH_DETECTION',
    UPDATE_MOTH_DETECTION_SUCCESS: 'UPDATE_MOTH_DETECTION_SUCCESS',
    UPDATE_MOTH_DETECTION_ERROR: 'UPDATE_MOTH_DETECTION_ERROR',

    VALIDATE_DETECTION: 'VALIDATE_DETECTION',
    VALIDATE_DETECTION_SUCCESS: 'VALIDATE_DETECTION_SUCCESS',
    VALIDATE_DETECTION_ERROR: 'VALIDATE_DETECTION_ERROR',

    initialize: () => ({
        type: actions.INITIALIZE_BASE_DETECTIONS
    }),

    initializeVegetationDetection: () => ({
        type: actions.INITIALIZE_VEGETATION_DETECTION,
    }),

    initializeApoideaDetection: () => ({
        type: actions.INITIALIZE_APOIDEA_DETECTION,
    }),

    initializeMothDetection: () => ({
        type: actions.INITIALIZE_MOTH_DETECTION,
    }),

    getAll: (data) => ({
        type: actions.GET_ALL_BASE_DETECTIONS,
        payload:data
    }),

    getVegetationDetection: (id) => ({
        type: actions.GET_VEGETATION_DETECTION,
        payload:id
    }),

    getApoideaDetection: (id) => ({
        type: actions.GET_APOIDEA_DETECTION,
        payload:id
    }),

    getMothDetection: (id) => ({
        type: actions.GET_MOTH_DETECTION,
        payload:id
    }),

    updateVegetationRecord: (id,data) => ({
        type: actions.UPDATE_VEGETATION_RECORD,
        id:id,
        payload:data
    }),

    updateApoideaRecord: (id,data) => ({
        type: actions.UPDATE_APOIDEA_RECORD,
        id:id,
        payload:data
    }),

    updateMothRecord: (id,data) => ({
        type: actions.UPDATE_MOTH_RECORD,
        id:id,
        payload:data
    }),

    validateDetection: (id,type) => ({
        type: actions.VALIDATE_DETECTION,
        id:id,
        detectionType:type
    }),

    updateApoideaDetection: (id,data) => ({
        type: actions.UPDATE_APOIDEA_DETECTION,
        id:id,
        payload:data
    }),

    updateMothDetection: (id,data) => ({
        type: actions.UPDATE_MOTH_DETECTION,
        id:id,
        payload:data
    }),


};
export default actions;
