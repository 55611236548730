const actions = {
    LOAD_ENTITIES: 'LOAD_ENTITIES',
    LOAD_ENTITIES_COMPLETE: 'LOAD_ENTITIES_COMPLETE',
    LOAD_ENTITIES_ERROR: 'LOAD_ENTITIES_ERROR',
    LOAD_ENTITY: 'LOAD_ENTITY',
    LOAD_ENTITY_COMPLETE: 'LOAD_ENTITY_COMPLETE',
    LOAD_ENTITY_ERROR: 'LOAD_ENTITY_ERROR',
    SAVE_ENTITY: 'SAVE_ENTITY',
    SAVE_ENTITY_COMPLETE: 'SAVE_ENTITY_COMPLETE',
    SAVE_ENTITY_ERROR: 'SAVE_ENTITY_ERROR',
    CLEAN_ENTITY: 'CLEAN_ENTITY',
    CLEAN_ENTITIES: 'CLEAN_ENTITIES',
    DELETE_ENTITY: 'DELETE_ENTITY',
    DELETE_ENTITY_COMPLETE: 'DELETE_ENTITY_COMPLETE',
    DELETE_ENTITY_ERROR: 'DELETE_ENTITY_ERROR',
    ENABLE_DISABLE_TOGGLE_CUSTOM_ENDPOINT: "ENABLE_DISABLE_TOGGLE_CUSTOM_ENDPOINT",
    ENABLE_DISABLE_TOGGLE_CUSTOM_ENDPOINT_SUCCESS: "ENABLE_DISABLE_TOGGLE_CUSTOM_ENDPOINT_SUCCESS",
    ENABLE_DISABLE_TOGGLE_CUSTOM_ENDPOINT_ERROR: "ENABLE_DISABLE_TOGGLE_CUSTOM_ENDPOINT_ERROR",


    loadEntities: (data) => (
        {
            type: actions.LOAD_ENTITIES,
            entityToLoad: data.entityToLoad,
            queryString: data.queryString,
            customEndpoint: data.customEndpoint,
            searchFilter: data.searchFilter,
            customUIClassName: data.customUIClassName,
            reduxNamespace: data.reduxNamespace,
            withoutLoader: data.withoutLoader,
            limit: data.limit,
            offset: data.offset,
            sorterOrder: data.sorterOrder,
            sorterField: data.sorterField,
            isServerPaginationActive: data.isServerPaginationActive,
            verb: data.verb
        }),
    loadEntity: (data) => ({
        type: actions.LOAD_ENTITY,
        entityToLoad: data.entityToLoad,
        id: data.id,
        customUIClassName: data.customUIClassName,
        customEndpoint: data.customEndpoint,
        customGetFormEndpoint: data.customGetFormEndpoint
    }),
    saveEntity: (data) => ({
        type: actions.SAVE_ENTITY,
        entityToSave: data.entityToSave,
        entity: data.entity,
        customEndpoint: data.customEndpoint,
        postRequestWithFormData: data.postRequestWithFormData
    }),
    deleteEntity: (data) => ({
        type: actions.DELETE_ENTITY,
        entityToDelete: data.entityToDelete,
        id: data.id,
        customEndpoint: data.customEndpoint
    }),
    cleanEntity: () => ({
        type: actions.CLEAN_ENTITY
    }),
    cleanEntities: () => ({
        type: actions.CLEAN_ENTITIES
    }),
    tableToggleSliderWithCustomEndpoint: (data, endpoint) => ({
        type: actions.ENABLE_DISABLE_TOGGLE_CUSTOM_ENDPOINT,
        endpoint: endpoint,
        payload: data,
    }),


};
export default actions;
