import actions from './actions';
import createNotification from "@src/components/Notification";

const initState = {
    apoideaDetections:null,
    vegetationDetections:null,
    mothDetections:null,
    vegetationDetection:null,
    apoideaDetection:null,
    mothDetection:null,
    updatePage:false,
    updateList:false,
    backToList:false
};

export default function baseDetection(state = initState, action) {
    switch (action.type) {
        case actions.INITIALIZE_BASE_DETECTIONS:
            return {
                initState
            };
        case actions.INITIALIZE_VEGETATION_DETECTION:
            return {
                ...state,
                vegetationDetection: null
            };
        case actions.INITIALIZE_APOIDEA_DETECTION:
            return {
                ...state,
                apoideaDetection: null
            };
        case actions.INITIALIZE_MOTH_DETECTION:
            return {
                ...state,
                mothDetection: null
            };
        case actions.GET_ALL_BASE_DETECTIONS:
            return {
                ...state,
                apoideaDetections: null,
                vegetationDetections: null,
                mothDetections: null,
            };
        case actions.GET_ALL_BASE_DETECTIONS_SUCCESS:
            return {
                ...state,
                apoideaDetections: action.payload.apoideaDetections,
                vegetationDetections: action.payload.vegetationDetections,
                mothDetections: action.payload.mothDetections,
                updateList:false,
                backToList:false
            };
        case actions.GET_VEGETATION_DETECTION:
            return {
                ...state,
                //vegetationDetection: null
            };
        case actions.GET_VEGETATION_DETECTION_SUCCESS:
            return {
                ...state,
                vegetationDetection: action.payload,
                updatePage: false
            };
        case actions.GET_APOIDEA_DETECTION:
            return {
                ...state,
                //apoideaDetection: null
            };
        case actions.GET_APOIDEA_DETECTION_SUCCESS:
            return {
                ...state,
                apoideaDetection: action.payload,
                updatePage: false
            };
        case actions.GET_MOTH_DETECTION:
            return {
                ...state,
                //mothDetection: null
            };
        case actions.GET_MOTH_DETECTION_SUCCESS:
            return {
                ...state,
                mothDetection: action.payload,
                updatePage: false
            };
        case actions.UPDATE_VEGETATION_RECORD:
            return {
                ...state,
                updatePage: false
            };
        case actions.UPDATE_VEGETATION_RECORD_SUCCESS:
            createNotification('success', 'Operazione avvenuta con successo')
            return {
                ...state,
                updatePage: true
            };
        case actions.UPDATE_APOIDEA_RECORD:
            return {
                ...state,
                updatePage: false
            };
        case actions.UPDATE_APOIDEA_RECORD_SUCCESS:
            createNotification('success', 'Operazione avvenuta con successo')
            return {
                ...state,
                updatePage: true
            };
        case actions.UPDATE_MOTH_RECORD:
            return {
                ...state,
                updatePage: false
            };
        case actions.UPDATE_MOTH_RECORD_SUCCESS:
            createNotification('success', 'Operazione avvenuta con successo')
            return {
                ...state,
                updatePage: true
            };
        case actions.VALIDATE_DETECTION:
            return {
                ...state,
                updateList: false,
                backToList:false
            };
        case actions.VALIDATE_DETECTION_SUCCESS:
            createNotification('success', 'Operazione avvenuta con successo')
            return {
                ...state,
                updateList: true,
                backToList:true
            };
        case actions.UPDATE_APOIDEA_DETECTION:
            return {
                ...state,
                updatePage: false
            };
        case actions.UPDATE_APOIDEA_DETECTION_SUCCESS:
            createNotification('success', 'Operazione avvenuta con successo')
            return {
                ...state,
                updatePage: true
            };
        case actions.UPDATE_MOTH_DETECTION:
            return {
                ...state,
                updatePage: false
            };
        case actions.UPDATE_MOTH_DETECTION_SUCCESS:
            createNotification('success', 'Operazione avvenuta con successo')
            return {
                ...state,
                updatePage: true
            };
        default:
            return state;
    }
}