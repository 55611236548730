import {combineReducers} from 'redux';
import App from '@src/redux/app/reducer';
import Auth from '@src/redux/auth/reducer';
import ThemeSwitcher from '@src/redux/themeSwitcher/reducer';
import CRUD from '@src/redux/crud/reducer';
import UserSettings from '@src/redux/userSettings/reducer'
import LanguageSwitcher from '@src/redux/languageSwitcher/reducer';
import CustomUser from '@src/redux/customUser/reducer';
import Company from '@src/redux/company/reducer';
import Transept from '@src/redux/transept/reducer';
import Detection from '@src/redux/detection/reducer';
import BaseDetection from '@src/redux/baseDetection/reducer';
import Data from '@src/redux/data/reducer';

export default combineReducers({
    Auth,
    App,
    ThemeSwitcher,
    LanguageSwitcher,
    CRUD,
    UserSettings,
    CustomUser,
    Company,
    Transept,
    Detection,
    BaseDetection,
    Data
});
