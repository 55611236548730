const actions = {

    INITIALIZE_CUSTOM_USER: 'INITIALIZE_CUSTOM_USER',

    GET_ALL_USERS: 'GET_ALL_USERS',
    GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
    GET_ALL_USERS_ERROR: 'GET_ALL_USERS_ERROR',

    GET_USER: 'GET_USER',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_ERROR: 'GET_USER_ERROR',

    SAVE_USER: 'SAVE_USER',
    SAVE_USER_SUCCESS: 'SAVE_USER_SUCCESS',
    SAVE_USER_ERROR: 'SAVE_USER_ERROR',

    UPDATE_USER: 'UPDATE_USER',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',

    DELETE_USER: 'DELETE_USER',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_ERROR: 'DELETE_USER_ERROR',

    SEARCH_USER_BY_NAME_LIKE: 'SEARCH_USER_BY_NAME_LIKE',
    SEARCH_USER_BY_NAME_LIKE_SUCCESS: 'SEARCH_USER_BY_NAME_LIKE_SUCCESS',
    SEARCH_USER_BY_NAME_LIKE_ERROR: 'SEARCH_USER_BY_NAME_LIKE_ERROR',


    initializeCustomUser: () => ({
        type: actions.INITIALIZE_CUSTOM_USER
    }),

    getAll: (pagination) => ({
        type: actions.GET_ALL_USERS,
        pagination: pagination
    }),

    getCustomUserById: (entityToLoadId) => ({
        type: actions.GET_USER,
        id: entityToLoadId,
    }),

    save: (data) => ({
        type: actions.SAVE_USER,
        payload: data
    }),

    update: (data) => ({
        type: actions.UPDATE_USER,
        payload: data,
    }),

    deleteData: (id) => ({
        type: actions.DELETE_USER,
        id: id
    }),

    searchCustomUserByNameLike: (nameSearch,role) => ({
        type: actions.SEARCH_USER_BY_NAME_LIKE,
        name: nameSearch,
        role:role
    }),

};
export default actions;
