import actions from './actions';
import createNotification from "@src/components/Notification";

const initState = {
    detections: null,
    detection: null,
    updateList: false,
    updatePage: false,
    entityToLoadId: null,
    backToList: false,
    autocomplete: null,
    detectionDetails: null,
    detectionFromUploadSaved : null
};

export default function detection(state = initState, action) {
    switch (action.type) {
        case actions.INITIALIZE_DETECTION:
            return {
                initState,
                updateList: true
            };
        case actions.INITIALIZE_DETECTION_DETAILS:
            return {
                ...state,
                detectionDetails: null
            };
        case actions.GET_ALL_DETECTION_SUCCESS:
            return {
                ...state,
                detections: action.payload.value,
                totalResults: action.payload.totalResults,
                updateList: false,
                backToList: false
            };
        case actions.GET_DETECTION:
            return {
                ...state,
                detection: null,
                updatePage: false
            };
        case actions.GET_DETECTION_SUCCESS:
            return {
                ...state,
                detection: action.payload.value,
                updatePage: true,

            };
        case actions.SAVE_DETECTION:
            return {
                ...state,
                updateList: false,
                backToList: false
            };
        case actions.SAVE_DETECTION_SUCCESS:
            createNotification('info', 'Operazione avvenuta con successo')
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.UPDATE_DETECTION:
            return {
                ...state,
                backToList: false,
                updateList: false
            };
        case actions.UPDATE_DETECTION_SUCCESS:
            createNotification('info', 'Operazione avvenuta con successo')
            return {
                ...state,
                backToList: true,
                updateList: true
            };
        case actions.DELETE_DETECTION:
            return {
                ...state,
                updateList: false
            };
        case actions.DELETE_DETECTION_SUCCESS:
            createNotification('info', 'Operazione avvenuta con successo')
            return {
                ...state,
                updateList: true
            };
        case actions.ASSIGN_VEGETABLE:
            return{
                ...state,
                updateList: false
            }
        case actions.ASSIGN_VEGETABLE_SUCCESS:
            return{
                ...state,
                vegetableReporterId: action.payload.id,
                vegetableReporterUsername : action.payload.username,
                updateList: true
            }
        case actions.ASSIGN_APOIDEA:
            return{
                ...state,
                updateList: false
            }
        case actions.ASSIGN_APOIDEA_SUCCESS:
            return{
                ...state,
                apoideaReporterId: action.payload.id,
                apoideaReporterUsername : action.payload.username,
                updateList: true
            }
        case actions.ASSIGN_MOTH:
            return{
                ...state,
                updateList: false
            }
        case actions.ASSIGN_MOTH_SUCCESS:
            return{
                ...state,
                mothReporterId: action.payload.id,
                mothReporterUsername : action.payload.username,
                updateList: true
            }
        case actions.GET_DETECTION_DETAILS_SUCCESS:
            return{
                ...state,
                detectionDetails: action.payload
            }
        case actions.GET_DETECTION_DETAILS_ERROR:
            return{
                ...state
            }
        case actions.SAVE_DETECTION_FROM_UPLOAD_SUCCESS:
            return{
                ...state,
                detectionFromUploadSaved: action.payload
            }
        case actions.SAVE_DETECTION_FROM_UPLOAD_ERROR:
            return{
                ...state
            }

        default:
            return state;
    }
}
