import {all} from 'redux-saga/effects';
import authSaga from '@src/redux/auth/saga';
import crudSagas from '@src/redux/crud/saga';
import userSettingsSaga from '@src/redux/userSettings/saga'
import CustomUser from "@src/redux/customUser/saga";
import Company from "@src/redux/company/saga";
import Transept from "@src/redux/transept/saga";
import Detection from "@src/redux/detection/saga";
import BaseDetection from "@src/redux/baseDetection/saga";
import Data from "@src/redux/data/saga";

export default function* rootSaga(getState) {
    yield all([
        authSaga(),
        crudSagas(),
        userSettingsSaga(),
        CustomUser(),
        Company(),
        Transept(),
        Detection(),
        BaseDetection(),
        Data()
    ]);
}
