import actions from './actions';

const initState = {
    data: [],
    loading: false,
    currentEntity: {},
    entities: [],
    customNamespaceEntities: {},
    totalCount: 0,
};

export default function crudReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOAD_ENTITIES:
            let newState = {...state, entityToLoad: action.entityToLoad, deletedEntity: undefined}
            if (!action.withoutLoader) {
                newState.loading = true
            }
            return newState
        case actions.LOAD_ENTITIES_COMPLETE:
            const entities = action.payload;
            const entitiesData = {
                entities: entities.value,
                totalCount: entities.totalRows,
                loading: false,
                deletedEntity: undefined
            };
            if (action.reduxNamespace) {
                const entitiesState = {};
                entitiesState[action.reduxNamespace] = {...state[action.reduxNamespace], ...entitiesData};
                return {...state, customNamespaceEntities: entitiesState}
            } else {
                return {...state, ...entitiesData}
            }

        case actions.CLEAN_ENTITY:
            return {
                ...state,
                currentEntity: {},
                savedEntity: null
            };
        case actions.CLEAN_ENTITIES:
            return {
                ...state,
                entities: null,
                customNamespaceEntities: {}
            };
        case actions.SAVE_ENTITY:
            return {...state};
        case actions.LOAD_ENTITY_COMPLETE: {
            return {
                ...state,
                currentEntity: (action.payload.value) ? action.payload.value : action.payload.value,
                savedEntity: null
            };
        }
        case actions.SAVE_ENTITY_COMPLETE:
            return {...state, savedEntity: (action.payload.value) ? action.payload.value : action.payload.value};
        case actions.SAVE_ENTITY_ERROR:
            return {...state, savedEntityError: action.payload};
        case actions.DELETE_ENTITY_COMPLETE:
            return {...state, deletedEntity: {deletedEntity: true}, currentEntity: {}};
        case actions.ENABLE_DISABLE_TOGGLE_CUSTOM_ENDPOINT_SUCCESS:
            let updatedEntitiesToggle = {entities: [...state.entities]};
            let oldObjectIndexToggle = updatedEntitiesToggle.entities.findIndex(object => object.id === action.payload.id);
            updatedEntitiesToggle.entities[oldObjectIndexToggle].enabled = !updatedEntitiesToggle.entities[oldObjectIndexToggle].enabled;
            return {...state, ...updatedEntitiesToggle};
        case actions.ENABLE_DISABLE_TOGGLE_CUSTOM_ENDPOINT_ERROR:
            return {...state};
        default:
            return state;
    }
}
