const actions = {

    INITIALIZE_COMPANY: 'INITIALIZE_COMPANY',

    GET_ALL_COMPANIES: 'GET_ALL_COMPANIES',
    GET_ALL_COMPANIES_SUCCESS: 'GET_ALL_COMPANIES_SUCCESS',
    GET_ALL_COMPANIES_ERROR: 'GET_ALL_COMPANIES_ERROR',

    GET_COMPANY: 'GET_COMPANY',
    GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
    GET_COMPANY_ERROR: 'GET_COMPANY_ERROR',

    SAVE_COMPANY: 'SAVE_COMPANY',
    SAVE_COMPANY_SUCCESS: 'SAVE_COMPANY_SUCCESS',
    SAVE_COMPANY_ERROR: 'SAVE_COMPANY_ERROR',

    UPDATE_COMPANY: 'UPDATE_COMPANY',
    UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
    UPDATE_COMPANY_ERROR: 'UPDATE_COMPANY_ERROR',

    DELETE_COMPANY: 'DELETE_COMPANY',
    DELETE_COMPANY_SUCCESS: 'DELETE_COMPANY_SUCCESS',
    DELETE_COMPANY_ERROR: 'DELETE_COMPANY_ERROR',

    
    initializeCompany: () => ({
        type: actions.INITIALIZE_COMPANY
    }),

    getAll: (pagination) => ({
        type: actions.GET_ALL_COMPANIES,
        pagination: pagination
    }),

    getCompanyById: (entityToLoadId) => ({
        type: actions.GET_COMPANY,
        id: entityToLoadId,
    }),

    save: (data) => ({
        type: actions.SAVE_COMPANY,
        payload: data
    }),

    update: (data) => ({
        type: actions.UPDATE_COMPANY,
        payload: data,
    }),

    deleteData: (id) => ({
        type: actions.DELETE_COMPANY,
        id: id
    })

};
export default actions;
